import React from 'react';
import styled from 'styled-components';
import { Accordion } from 'components/accordion';
import faqData from 'jsons/faq.json';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SFaqSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 4rem auto;
  width: 100%;
  max-width: 88.125rem;
  ${MEDIA.max1024`
      flex-direction: column;
    `}
`;

const SLeftColumn = styled.div`
  width: 40%;
  ${MEDIA.max1024`
    width: 100%;
    padding-right: 0;
    order: -1;
  `}
`;

const SRightColumn = styled.div`
  width: 60%;
  ${MEDIA.max1024`
    width: 100%;
    padding-left: 0;
  `}
`;

const STitle = styled.h2`
  font-size: 3rem;
  color: ${COLORS.DARK};
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  font-family: 'Abhaya Libre', serif;
  text-align: left;
  ${MEDIA.max1024`
    text-align: center;
  `}
`;

const SDescription = styled.p`
  font-size: 1.4rem;
  color: ${COLORS.GREY};
  margin-right: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  ${MEDIA.max1024`
    text-align: center;
  `}
`;

export const FaqSection = () => {
  return (
    <SFaqSection>
      <SLeftColumn>
        <STitle>Najczęściej Zadawane Pytania</STitle>
        <SDescription>
          Przedstawiamy najczęściej zadawane pytania przez naszych pacjentów
          oraz odpowiedzi jakie na nie udzielamy. W przypadku dodatkowych pytań
          zapraszamy do konsultacji w naszym gabinecie w Warszawie.
        </SDescription>
      </SLeftColumn>
      <SRightColumn>
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            index={index}
            title={faq.question}
            content={faq.answer}
            totalAccordions={faqData.length}
          />
        ))}
      </SRightColumn>
    </SFaqSection>
  );
};
